import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol'
import { declOfNum } from '../../../../utils/declOfNum'
import styles from './ProductsAndPrice.module.scss'
import React, { useMemo, useState } from 'react'

function ProductsAndPrice({
  price,
  countSalesStatus,
  bindingProduct,
  products,
  promoRes,
  currency,
  oldPrice
}) {
  const [showProd, setShowProd] = useState(false)

  const salePrice = useMemo(() => {
    return promoRes?.products?.reduce((sum, el) => sum + el.price_difference, 0)
  }, [promoRes])

  return (
    <>
      {!countSalesStatus && !bindingProduct && (
        <div data-testid="products-title" className={styles.productsTitle}>
          <div data-testid="product-amount" className={styles.prodAmount}>
            {products?.length === 1 ? 'Выбран' : 'Выбрано'}{' '}
            {products?.length || 0}{' '}
            {declOfNum(products?.length, ['продукт', 'продукта', 'продуктов'])}
          </div>
          <div
            data-testid="toggle-products-button"
            className={styles.showProdBtn}
            onClick={() => setShowProd(!showProd)}
          >
            {showProd ? 'Скрыть продукты' : 'Показать продукты'}
          </div>
        </div>
      )}
      {!countSalesStatus && promoRes.success
        ? showProd &&
          promoRes.products?.map((elem, i) => (
            <div
              data-testid={`product-row-${i}`}
              className={styles.productsRow}
              key={i}
            >
              <div
                data-testid={`frame-products-${i}`}
                className={styles.frameProducts}
              >
                <div
                  data-testid={`product-number-${i}`}
                  className={styles.number}
                >
                  {i + 1}.
                </div>
                <div>
                  <div
                    data-testid={`product-title-${i}`}
                    className={styles.frameProductTitle}
                  >
                    {elem.name}
                  </div>
                  <div
                    data-testid={`product-description-${i}`}
                    className={styles.frameProductDescription}
                  >
                    {elem.description}
                  </div>
                </div>
              </div>
              <div>
                <div
                  data-testid={`product-price-${i}`}
                  className={styles.frameProdPrice}
                >
                  {elem.new_price < 0
                    ? 0
                    : elem.new_price?.toLocaleString('ru-RU')}{' '}
                  {getCurrencySymbol(currency)}
                </div>
                {elem.price_difference !== 0 && (
                  <div
                    data-testid={`old-price-container-${i}`}
                    className={styles.frameOldPrice}
                  >
                    <div
                      data-testid={`old-price-${i}`}
                      className={styles.oldPrice}
                    >
                      {elem.price?.toLocaleString('ru-RU')}{' '}
                      {getCurrencySymbol(currency)}
                    </div>
                    {promoRes.discount_percent_status ? (
                      <div
                        data-testid={`price-difference-percent-${i}`}
                        className={styles.priceDifference}
                      >{`-${promoRes.discount}%`}</div>
                    ) : (
                      <div
                        data-testid={`price-difference-amount-${i}`}
                        className={styles.priceDifference}
                      >{`-${
                        elem.price_difference
                      } ${getCurrencySymbol(currency)}`}</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))
        : showProd &&
          products?.map((el, i) => (
            <div
              data-testid={`product-row-${i}`}
              className={styles.productsRow}
              key={i}
            >
              <div
                data-testid={`frame-products-${i}`}
                className={styles.frameProducts}
              >
                <div
                  data-testid={`product-number-${i}`}
                  className={styles.number}
                >
                  {i + 1}.
                </div>
                <div>
                  <div
                    data-testid={`product-title-${i}`}
                    className={styles.frameProductTitle}
                  >
                    {el.name === null
                      ? el.product_info.custom_name === null
                        ? el.product_info.name
                        : el.product_info.custom_name
                      : el.name}
                  </div>
                  <div
                    data-testid={`product-description-${i}`}
                    className={styles.frameProductDescription}
                  >
                    {el.description === null
                      ? el.product_info.description
                      : el.description}
                  </div>
                </div>
              </div>
              <div>
                <div
                  data-testid={`product-price-${i}`}
                  className={styles.frameProdPrice}
                >
                  {(el.price === null
                    ? el.product_info.price
                    : el.price
                  )?.toLocaleString('ru-RU')}{' '}
                  {getCurrencySymbol(currency)}
                </div>
              </div>
            </div>
          ))}

      {!bindingProduct ? (
        <div data-testid="line-separator" className={styles.line} />
      ) : (
        <div data-testid="empty-line-separator" className={styles.emptyLine} />
      )}

      <div data-testid="price-summary" className={styles.priceRow}>
        {!countSalesStatus && (
          <div data-testid="total-price" className={styles.price}>
            <span data-testid="total-label" className={styles.sum}>
              Итого:
            </span>
            <span data-testid="total-amount" className={styles.sumPrice}>
              {price?.toLocaleString('ru-RU')}{' '}
              {getCurrencySymbol(currency) || '₽'}
            </span>
          </div>
        )}
        {!countSalesStatus && promoRes.success && (
          <div data-testid="promo-summary" className={styles.sumPromo}>
            <div data-testid="old-price-summary" className={styles.oldSum}>
              <div data-testid="old-price-label" className={styles.sum}>
                Цена без скидки:
              </div>
              <div data-testid="old-price-amount" className={styles.oldPrice}>
                {oldPrice?.toLocaleString('ru-RU')}{' '}
                {getCurrencySymbol(currency)}
              </div>
            </div>
            <div data-testid="discount-summary" className={styles.sale}>
              <div data-testid="discount-label" className={styles.sum}>
                {' '}
                Скидка:
              </div>
              <div data-testid="discount-amount" className={styles.saleSum}>
                <div data-testid="discount-price" className={styles.salePrice}>
                  {salePrice?.toLocaleString('ru-RU')}{' '}
                  {getCurrencySymbol(currency)}
                </div>
                {promoRes.discount_percent_status ? (
                  <div
                    data-testid="discount-percent"
                    className={styles.priceDifference}
                  >{`-${promoRes.discount}%`}</div>
                ) : (
                  <div
                    data-testid="discount-amount-value"
                    className={styles.priceDifference}
                  >
                    {`-${salePrice?.toLocaleString('ru-RU')} ${getCurrencySymbol(
                      currency
                    )}`}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ProductsAndPrice
